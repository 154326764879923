import React from 'react'

import SEO from '../blocks/layout/seo'
import AppContainer from '../blocks/layout/appContainer'

const Usage = () => {
  return (
    <>
      <SEO title="One place for all your forms — FormWithSheet" />
      <AppContainer menuKey="2">
        <h2>Things to finish.</h2>
      </AppContainer>
    </>
  )
}

export default Usage
